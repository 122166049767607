.navbar {
  background-color: #ffedfe;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  height: 40px;
  z-index: 999; /* Ensure navbar appears above other content */
  top: 0;
}

.navbar-content {
  display: flex;
  align-items: center;
  max-width: 100%;
  padding-top: 5px;
}

.navbar-title {
  color: #f26da7;
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  margin-left: 30px;
}

.navbar-items {
  display: flex;
  margin-left: 5em;
  gap: 30px;
}

.navbar-item {
  font-size: 1em;
  color: #5f43cc;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  font-weight: bold;
  text-transform: uppercase;
}

.navbar-item:hover {
  color: #ff00b7;
}

.navbar-item.company-name {
  margin-left: auto;
  color: #ff00b7;
  font-weight: bold;
  margin-right: 30px;
}


@media (max-width: 576px) {

  .navbar {
    height: 40px;
  }

  .navbar-content {
    display: flex;
    align-items: center;
    max-width: 100%;
    padding-top: 5px;
  }

  .navbar-title {
    color: #f26da7;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    margin-left: 10px;
  }

  .navbar-items {
    display: none;
    margin-left: 1em;
    gap: 5px;
  }

  .navbar-item {
    font-size: 10px;
  }

  .navbar-item.company-name {
    margin-left: auto;
    color: #ff00b7;
    font-weight: bold;
    margin-right: 10px;
  }
 }