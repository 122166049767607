.container {
  width: 100%;
  text-align: end;
}

.download-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #f26da7;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .download-button:hover {
    background-color: #ff6699;
  }
  
  .download-button:disabled {
    background-color: #ccc; /* or any other color you want for disabled state */
    cursor: not-allowed;
  }