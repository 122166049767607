.password-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  background: url('../../assets/bg-login.jpg') no-repeat center center fixed; 
  background-size: cover;
  background-position: center;
}

.password-page .title {
  padding: 15px 25px;
  border: 5px solid pink;
  background-color: #6266d9;
  color: #eee;
}

.password-page .form-container {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 15px;
  width: 100%;
  max-width: 500px;
}

.password-page h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.password-page form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.password-page form input[type="text"],
.password-page form input[type="password"],
.password-page form input[type="email"],
.password-page form input[type="tel"],
.password-page form select {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}



.password-page form .form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.password-page form .half-width-input {
  width: 48%; /* Adjust width as needed */
}

.password-page form input[type="password"] {
  margin-top: 15px;
  border-color: #6266d9;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: rgb(201, 201, 201);
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
}

.password-page form button {
  padding: 10px;
  border: none;
  background-color: #6266d9;
  color: white;
  font-weight: bold;
  border-radius: 4px;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 5px;
}

.password-page form button:hover {
  background-color: #0056b3;
}

.password-page .error-message {
  color: red;
  margin-top: 10px;
}

.requirements-checkbox {
  font-weight: bold;
  padding: 10px;
  display: flex;
  gap: 5px;
}

.requirements-box {
  background-color: #eee;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 20px;
}
