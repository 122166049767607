

.card {
  display: flex;
  border-radius: 5px;
  align-items: start;
  box-shadow: 0 2px 5px #ccc;
  margin: 14px;
  width: 580px; /* Set the width of the card */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
}

.best-seller-badge {
  display: inline-block;
  padding: 2px 8px;
  background-color: gold;
  color: black;
  font-size: 12px;
  border-radius: 10px;
  margin-left: 5px;
}

.card .image-section {
  height: 100%;
  display: flex;
  position: relative;
  cursor: pointer;
}

.card img {
  width: 85px;
  height: auto;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.card .image-section .image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); /* change this color if needed */
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  color: #fff; /* color of the eye icon */
  font-size: 2em; /* size of the eye icon */
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.card .image-section:hover .image-overlay {
  opacity: 1;
}

.card .info-section {
  flex:7;
  padding: 8px;
  display: flex;
  flex-direction: column;
  margin-left: 14px;
  gap: 1px
}

.card .info-section .name {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  color: #6266d9;
}

.card .info-section .price {
  color: #494949;
  margin: 2px;
  font-size: 12px;
  font-weight: 500;
}

.card .quantity-section {
  flex: 3;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 14px;
}

.card .quantity-section label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 14px;
  color: #6266d9;
  
}

.card .quantity-section .quantity-selector {
  margin-bottom: 14px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  font-size: 12px;
  width: 60px;
  text-align: center;
  color: #333;
}

.card .quantity-section div {
  display: flex;
  align-items: center;
}

.card .quantity-section button {
  width: 30px;
  height: 30px;
  border:none;
  background-color: #f26da7;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.card .quantity-section button:hover {
  background-color: #f24e95;
}

.card .quantity-section .quantity-input {
  width: 50px;
  height: 30px;
  text-align: center;
  border: 1px solid #f24e956d;
  margin: 0 10px;
  padding: 0;
}


.card .chip {
  background-color: #007bff;
  color: white;
  padding: 3px 8px;
  border-radius: 5px;
  font-size: 12px;
  text-transform: uppercase;
  width: fit-content;
  font-weight: bold;
}

.new-badge {
  padding: 4px;
  background-color: #f26da7;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
}

.chips-container {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
}

@media (max-width: 576px) {
  .card {
    display: flex;
    align-items: start;
    margin-left: auto;
    margin-right: auto;
    width: 300px; /* Set the width of the card */
  }

  .chips-container {
    display: none;
  }

  .card .info-section {
    flex:7;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    gap: 0px
  }

  .card .info-section .name {
    font-size: 10px;
    font-weight: bold;
    margin: 0;
    color: #6266d9;
    margin-bottom: 10px
  }
  
  .card .info-section .price {
    color: #494949;
    margin: 0px;
    font-size: 9px;
    font-weight: 500;
  }

  .card .quantity-section {
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0px;
  }

  .card .quantity-section label {
    font-size: 10px;
    font-weight: bold;
    margin-bottom: 14px;
    color: #6266d9;
    
  }
  
  .card .quantity-section .quantity-selector {
    margin-bottom: 14px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #fff;
    font-size: 12px;
    width: 60px;
    text-align: center;
    color: #333;
  }
  
  .card .quantity-section div {
    display: flex;
    align-items: center;
  }
  
  .card .quantity-section button {
    width: 25px;
    height: 25px;
    border:none;
    background-color: #f26da7;
    color: white;
    font-size: 10px;
    cursor: pointer;
  }
  
  .card .quantity-section button:hover {
    background-color: #f24e95;
  }
  
  .card .quantity-section .quantity-input {
    width: 40px;
    height: 25px;
    text-align: center;
    border: 1px solid #f24e956d;
    margin: 0 5px;
    padding: 0;
  }

  .quantity-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}