.basket-container {
  display: flex;
  position: fixed;
  top: 95px;
  right: 50px;
  flex-direction: column;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  width: 400px;
  padding: 10px 20px;
  background-color: #fafafa;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  max-height: 80vh; /* 90% of the view height */
  overflow-y: auto; /* Enable scrolling */
}

.title {
  margin-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
  font-size: 24px;
  display: flex;
  align-items: center;
  gap: 10px;
}


.list-element {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.delete-button {
  border: none;
  background: none;
  color: #e74c3c;
  font-size: 15px;
  margin-right: 10px;
  cursor: pointer;
}

.product-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.product-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.product-name {
  font-size: 14px;
  color: #333;
}

.product-quantity-price {
  font-size: 14px;
  color: #777;
}

.total-price {
  font-size: 20px;
  color: #333;
  margin-top: 20px;
  border-top: 1px solid #eaeaea;
  padding-top: 10px;
  text-align: end;
}

.order-minimum {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  padding: 15px 25px;
  border: 1px solid red;
  background-color: white;
  font-weight: bold;
}

.basket-toggle-icon {
  display: none; /* Hidden by default */
  position: fixed;
  top: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  cursor: pointer;
  font-size: 24px; /* Adjust as needed */
}

@media (max-width: 576px) {
  
  .basket-toggle-icon {
    border: 1px solid #f26da7;
    background-color: #fff;
    border-radius: 50%;
    padding: 7px;
    top: 53px;
    right: 15px;
    z-index: 99;
    display: inline; /* Show only in mobile mode */
  }

  .basket-container {
    display: flex;    
    position: fixed;
    top: 100px;
    right: 0px;
    flex-direction: column;
    border: 1px solid #b8b8b8;
    border-radius: 5px;
    width: 90%;
    padding: 10px 20px;
  }
}
