.category-title {
    font-size: 24px;
    font-weight: bold;
    color: white;
    margin-bottom: 10px;
    background-color: #5F43CC;
    padding: 5px 16px;
    text-transform: uppercase;
  }

  .filter-button-container {
    display: flex;
    gap: 10px;
    margin-bottom: 1em;
    margin-top: 20px;
  }
  
  .filter-button {
    background-color: transparent;
    border: none;
    padding: 0;
    font-size: 1em;
    color: #5f43cc;
    cursor: pointer;
  }
  
  .filter-button.active {
    font-weight: bold;
  }
  
  @media (max-width: 576px) {

    .product-list-container {
      width: 100%;
    }

    .filter-button-container {
      margin-bottom: 0px;
      margin-top: 10px;
      padding-left: 15px;
    } 

    .category-title {
      font-size: 12px;
    }
  }