.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: flex-start; /* align the modal to the start (left) */
  z-index: 1000;
}

.modal-content {
  height: 100%;
  width: 500px;
  background: rgb(162,167,243);
  background: linear-gradient(83deg, rgba(162,167,243,1) 0%, rgba(255,255,255,1) 100%);
  overflow-y: auto;
  transform: translateX(-100%);
  animation: slideIn 0.5s forwards;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.modal-content img {
  max-width: 100% !important;
  max-height: 100% !important;
  width: auto !important;
  height: auto !important;
  margin: auto !important;
  display: block !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.close-button {
  position: absolute;
  top: 30px;
  right: 10px;
  border: none;
  background: none;
  color: #6266d9;
  cursor: pointer;
}

.close-button:hover {
  color: #d562d9;
}
