@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,500,300,700);

* {
  font-family: Open Sans;
}

.footer-distributed{
  background: rgb(162,167,243);
  background: linear-gradient(176deg, rgba(162,167,243,1) 0%, rgba(255,255,255,1) 100%);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font: bold 16px sans-serif;
  padding: 10px 20px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-distributed h3 span{
  color:  #6266D9;
}

.footer-distributed .footer-company-name{
  color:  #6266D9;
  font-size: 12px;
  font-weight: normal;
  margin: 0;
}

.footer-distributed .footer-center{
  display: flex;
  align-items: center;
  font-size: 12px;
}

.footer-distributed .footer-center i.fa-envelope{
  font-size: 12px;
}

.footer-distributed .footer-center p{
  margin:0;
}

.footer-distributed .footer-center p a{
  color:  #6266D9;
  text-decoration: none;
}

.footer-distributed .footer-right{
  display: flex;
  align-items: center;
}

.footer-distributed .footer-icons a{
  display: inline-block;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-color:  #6266D9;
  border-radius: 2px;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  line-height: 24px;
  margin-left: 5px;
}
