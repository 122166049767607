
html {
  scroll-behavior: smooth;
}

.app-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.app-body-container {
  display: flex;
  max-width: 1280px;
  padding-left: 50px;
  gap: 100px;
}

.catalog {
  display: flex;
  overflow-y: auto;
  margin-bottom: 1rem;
}

@media (max-width: 576px) {
  .app-body-container {
    padding-left: 0px;
  }

  .catalog {
    width: 100%;
  }
}

