
.profile-modal-button {
    background-color: #5f43cc;;
    color: #ffffff;
    border: 3px solid #5f43cc;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    width: 100%;
    transition: opacity 0.6s; /* Add transition */

    &:hover {
      opacity: 0.6;
    }
}

.profile-modal {
    position: fixed;
    z-index: 1;
    top: 65px; /* Adjust the top position */
    right: 0;
    width: 500px; /* Change width */
    height: 80%; /* Adjust height */
    overflow: auto;
    transition: transform 0.3s ease-out; /* Transition */
    transform: translateX(100%); /* Start from the right */
    box-shadow: 0 2px 5px rgba(0,0,0,0.4); /* Add box-shadow and remove the border */
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

  
  .profile-modal.show {
    transform: translateX(0); /* Slide in when .show class is added */
    background-color: #fefefe;
  }
  
  .profile-modal .profile-modal-content {
    
    padding: 20px 20px 50px 20px;
    height: 100%;
  }
  
  .profile-modal .profile-modal-content label {
    display: block;
    color: #5f43cc;
    margin-bottom: 3px; /* Reduce margin */
  }
  
  .profile-modal .profile-modal-content input {
    width: 90%; /* Reduce width */
    padding: 8px; /* Reduce padding */
    margin-bottom: 15px; /* Reduce margin */
    box-sizing: border-box;
    border: 1px solid lightgray;
    border-radius: 5px;
  }
  
  .profile-modal .profile-modal-content select {
    width: 90%; /* Reduce width */
    padding: 8px; /* Reduce padding */
    margin-bottom: 15px; /* Reduce margin */
    box-sizing: border-box;
    border: 1px solid lightgray;
    border-radius: 5px;
  }
  
  .profile-modal .profile-close-button {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .profile-modal .profile-close-button:hover,
  .profile-modal .profile-close-button:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .profile-modal .profile-modal-content button[type="submit"] {
    background-color: #5f43cc;;
    color: #ffffff;
    padding: 14px 20px;
    margin: 8px 0;
    border: 3px solid #5f43cc;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    transition: opacity 0.6s; /* Add transition */
  }
  
  .profile-modal .profile-modal-content button[type="submit"]:hover {
    opacity: 0.6; /* Add hover effect */
  }
  
  @media (max-width:576px) {
    .profile-modal {
      position: fixed;
      z-index: 1;
      top: 0px; /* Adjust the top position */
      right: 0;
      width: 100%; /* Change width */
      height: 100%; /* Adjust height */
      overflow: auto;
      transition: transform 0.3s ease-out; /* Transition */
      transform: translateX(100%); /* Start from the right */
      box-shadow: 0 2px 5px rgba(0,0,0,0.4); /* Add box-shadow and remove the border */
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      z-index:999
    }
  
  }